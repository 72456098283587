import "./styles.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CTAWpp from "../../components/CTAWpp";

import AVTR1 from "../../assets/content1_1.png";
import AVTR2 from "../../assets/content1_2.png";
import AVTR3 from "../../assets/content1_3.png";
import AVTR4 from "../../assets/content1_4.png";
import AVTR5 from "../../assets/content1_5.png";
import AVTR6 from "../../assets/content1_6.png";
import AVTR7 from "../../assets/content1_7.png";

import AVTR2_1 from "../../assets/content3_1.png";
import AVTR2_2 from "../../assets/content3_2.png";
import AVTR2_3 from "../../assets/content3_3.png";
import AVTR2_4 from "../../assets/content3_4.png";
import AVTR2_5 from "../../assets/content3_5.png";
import AVTR2_6 from "../../assets/content3_6.png";
import AVTR2_7 from "../../assets/content3_7.png";
import AVTR2_8 from "../../assets/content3_8.png";
import AVTR2_9 from "../../assets/content3_9.png";

import stars from "../../assets/stars.png";
import juliana from "../../assets/juliana.png";
import marcos from "../../assets/marcos.png";
import george from "../../assets/george.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data_content = [
  {
    id: 1,
    image: AVTR1,
  },
  {
    id: 2,
    image: AVTR2,
  },
  {
    id: 3,
    image: AVTR3,
  },
  {
    id: 4,
    image: AVTR4,
  },
  {
    id: 5,
    image: AVTR5,
  },
  {
    id: 6,
    image: AVTR6,
  },
  {
    id: 7,
    image: AVTR7,
  },
];

const data_content3 = [
  {
    id: 1,
    image: AVTR2_1,
  },
  {
    id: 2,
    image: AVTR2_2,
  },
  {
    id: 3,
    image: AVTR2_3,
  },
  {
    id: 4,
    image: AVTR2_4,
  },
  {
    id: 5,
    image: AVTR2_5,
  },
  {
    id: 6,
    image: AVTR2_6,
  },
  {
    id: 7,
    image: AVTR2_7,
  },
  {
    id: 8,
    image: AVTR2_8,
  },
  {
    id: 9,
    image: AVTR2_9,
  },
];

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <div className="left">
            <h2 className="title">PERSONALIZADO CONFORME SEU GOSTO</h2>

            <h3 className="subtitle">
              Confira nossos Projetos para Farmácias e Lojas
            </h3>

            <Swiper
              className="mobile"
              spaceBetween={0}
              slidesPerView={1}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data_content.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="item">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Loja`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <p className="text">
              Nosso foco é desenvolver produtos de qualidade e inovação,
              enquanto fornecemos consultoria especializada e apoio na criação
              de projetos. Trabalhamos em todas as etapas do processo, desde a
              elaboração da planta baixa até a visualização em 3D, culminando na
              execução da obra. Dessa forma, garantimos a entrega de um layout
              ideal para cada segmento, atendendo às necessidades específicas de
              cada cliente. Nosso compromisso é oferecer soluções completas e
              personalizadas, visando a excelência em cada projeto que
              realizamos.
            </p>

            <CTAWpp />
          </div>

          <Swiper
            className="carousel"
            spaceBetween={0}
            slidesPerView={1}
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data_content.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="item">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Loja`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <h2 className="title">
            Sua farmácia, drogaria, perfumaria, loja e papelaria com móveis
            exlusivos e sob medida. Design moderno para agregar mais valor ao
            seu negócio.
          </h2>

          <p className="text">
            Desenvolvemos projetos personalizados e exclusivos para atender às
            necessidades do seu negócio. Entre em contato conosco para solicitar
            um orçamento completo e detalhado para o seu projeto. Temos
            especialização em projetos para farmácias, perfumarias, drogarias e
            lojas de diversos segmentos. Nossa equipe está preparada para
            atender clientes em todo o território nacional, garantindo um
            serviço de qualidade em qualquer localidade. Não hesite em nos
            contatar para transformar sua visão em realidade e criar um ambiente
            comercial único e funcional para o sucesso do seu negócio.
          </p>

          <CTAWpp />
        </div>
      </article>

      <article className="content3">
        <div className="container">
          <h2 className="title">empresas atendidas pela abrafarma</h2>

          <Swiper
            className="carousel"
            spaceBetween={2}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 3
                : 4
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data_content3.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="item">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Loja`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </article>

      <article className="content4">
        <div className="container">
          <h2 className="title">CLIENTES SATISFEITOS</h2>

          <h3 className="subtitle">Depoimentos dos nossos clientes</h3>

          <div className="gallery">
            <div className="item">
              <figure className="stars">
                <img src={stars} alt="Estrelas" />
              </figure>

              <p className="text">
                “Estou extremamente satisfeito por ter escolhido a ABRAFRAMA
                para projetar e construir minha farmácia! Do atendimento inicial
                ao acompanhamento pós-obra, só tenho elogios e gratidão! Preço
                justo, entrega antecipada e móveis de excelente qualidade!
                Recomendo totalmente!”
              </p>

              <figure className="perfil">
                <img src={juliana} alt="Juliana Marques" />
              </figure>

              <p className="name">Juliana Marques</p>
            </div>

            <div className="item">
              <figure className="stars">
                <img src={stars} alt="Estrelas" />
              </figure>

              <p className="text">
                “Decidimos contratar a ABRAFARMA para construir nossa drogaria e
                o resultado foi perfeito, exatamente como tínhamos imaginado.
                Eles conseguiram captar todas as nossas ideias e criaram os
                móveis exatamente do jeito que queríamos. A negociação,
                fabricação e instalação foram impecáveis. Estamos extremamente
                satisfeitos com o serviço prestado.”
              </p>

              <figure className="perfil">
                <img src={marcos} alt="Marcos Bruno" />
              </figure>

              <p className="name">Marcos Brunos</p>
            </div>

            <div className="item">
              <figure className="stars">
                <img src={stars} alt="Estrelas" />
              </figure>

              <p className="text">
                “Os móveis da minha farmácia foram feitos pela ABRAFRARMA e
                ficaram perfeitos! Depois de buscar alguns orçamentos, muitos
                dos quais eram inviáveis, decidi fechar negócio com a ABRAFRARMA
                e fiquei extremamente satisfeito. O prazo de entrega foi muito
                rápido, a qualidade do serviço e dos materiais é excelente, e o
                preço foi justo, na minha opinião.”
              </p>

              <figure className="perfil">
                <img src={george} alt="George Felipo" />
              </figure>

              <p className="name">George Felipo</p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Content;
