import React from "react";
import "./styles.css";

import logo from "../../assets/logo_rodape.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure>
            <img src={logo} alt="Logo" />
          </figure>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="tel">
            <b>Telefone:</b> (11) 98201-8183
          </p>
          <p className="text">
            ABRAFARMA MÓVEIS - Todos os direitos reservados
          </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
