import "./styles.css";

import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";

import CTAWpp from "../../components/CTAWpp";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Projetamos e Fabricamos Móveis
            <span>sob medida para sua Farmácia</span>
          </h1>

          <CTAWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            Desenvolvemos e fabricamos móveis e projetos personalizados para o
            varejo farmacêutico, destacando-nos pela agilidade, inovação,
            personalização e economia.
          </h2>
        </article>
      </section>

      <section className="header3">
        <div className="container">
          <h2 className="title">
            <span>Maximize suas vendas</span>
            com uma farmácia totalmente equipada!
          </h2>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={icon1} alt="Projetos Personalizados" />
              </figure>

              <p className="text">Projetos Personalizados</p>
            </div>

            <div className="item">
              <figure>
                <img src={icon2} alt="Materiais de Qualidade" />
              </figure>

              <p className="text">Materiais de Qualidade</p>
            </div>

            <div className="item">
              <figure>
                <img src={icon3} alt="Profissionais Capacitados" />
              </figure>

              <p className="text">Profissionais Capacitados</p>
            </div>
          </div>

          <CTAWpp />
        </div>
      </section>
    </header>
  );
};

export default Header;
