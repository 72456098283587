import React from "react";
import "./styles.css";

import cta from "../../assets/call_whats.png";

const ctaWpp = () => {
  return (
    <figure id="ctaWpp">
      <a
        href="http://wa.me/+5511993631129?text=Gostaria%20de%20solicitar%20Orçamento%20para%20minha%20fármacia.%20Vim%20pelo%20site%20da%20AbraFarma."
        target="_blank"
        rel="noreferrer"
      >
        <img src={cta} alt="Chamada de ação para Whatsapp" />
      </a>
    </figure>
  );
};

export default ctaWpp;
