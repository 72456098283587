import "./styles.css";

import Form from "../../components/Form";

const FormPage = () => {
  return (
    <section id="formPage">
      <div className="container">
        <Form />
      </div>
    </section>
  );
};

export default FormPage;
