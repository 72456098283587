import "./styles.css";

import CTAWpp from "../../components/CTAWpp";

import image1 from "../../assets/image_1.png";
import image2 from "../../assets/image_2.png";
import image3 from "../../assets/image_3.png";
import bullet1 from "../../assets/bullet1.png";
import bullet2 from "../../assets/bullet2.png";
import bullet3 from "../../assets/bullet3.png";
import about3 from "../../assets/about3.png";
import about3Mobile from "../../assets/mobile/about3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <h2 className="title">Quem Somos</h2>
            <h3 className="subtitle">Sobre a ABRAFARMA</h3>

            <div className="mobile">
              <figure className="item1">
                <img src={image3} alt="Imagem 3" />
              </figure>
              <figure className="item2">
                <img src={image2} alt="Imagem 2" />
              </figure>
              <figure className="item3">
                <img src={image1} alt="Imagem 1" />
              </figure>
            </div>

            <p className="text">
              <span>
                A ABRAFARMA é uma empresa que tem como foco primordial o
                cliente, as tendências de mercado e a inovação. Estamos sempre
                prontos para encarar novos desafios.
              </span>

              <span>
                Nosso principal objetivo é fabricar mobiliário para o varejo
                farmacêutico, sem a necessidade de intermediários. Desenvolvemos
                móveis e soluções em total sinergia com nossos clientes.
              </span>

              <span>
                Contamos com uma equipe altamente qualificada, que utiliza os
                mais modernos projetos customizados. Utilizando materiais
                diversos e principalmente placas de MDF (madeira de média
                densidade), buscamos oferecer soluções personalizadas para
                atender às necessidades específicas de cada cliente.
              </span>

              <span>
                Nossos projetos têm como prioridade a maximização do espaço, a
                adaptação ao ambiente, a exposição dos produtos e o
                armazenamento adequado. Dessa forma, buscamos auxiliar nossos
                clientes na expansão de seus negócios e na fidelização de seus
                próprios clientes.
              </span>
            </p>
            <CTAWpp />
          </div>

          <div className="right">
            <figure className="item1">
              <img src={image3} alt="Imagem 3" />
            </figure>
            <figure className="item2">
              <img src={image2} alt="Imagem 2" />
            </figure>
            <figure className="item3">
              <img src={image1} alt="Imagem 1" />
            </figure>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="item">
            <div className="top">
              <figure>
                <img src={bullet1} alt="Bullet Point" />
              </figure>

              <div className="top_title">missão</div>
            </div>

            <p className="text">
              Nosso objetivo é criar móveis para o setor varejista farmacêutico
              de forma ágil, inovadora, personalizada e econômica, com a
              finalidade de alcançar excelentes resultados para os nossos
              clientes.
            </p>
          </div>

          <div className="item">
            <div className="top">
              <figure>
                <img src={bullet2} alt="Bullet Point" />
              </figure>

              <div className="top_title">visão</div>
            </div>

            <p className="text">
              Visamos obter reconhecimento como líderes no desenvolvimento de
              mobiliário farmacêutico no Brasil até o ano de 2025. Nosso
              compromisso é baseado em nossa integridade, dedicação aos
              clientes, espírito inovador, agilidade nos processos produtivos e
              transparência com o público.
            </p>
          </div>

          <div className="item">
            <div className="top">
              <figure>
                <img src={bullet3} alt="Bullet Point" />
              </figure>

              <div className="top_title">valores</div>
            </div>

            <p className="text">
              Somos pautados por valores fundamentais como integridade,
              comprometimento, transparência, inovação e agilidade.
            </p>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="left">
          <div className="item">
            <div className="item_title">
              Compromisso absoluto do começo ao fim
            </div>

            <p className="text">
              Juntando experiência, comprometimento e modernidade, garantimos o
              sucesso do seu comércio.
            </p>
          </div>

          <div className="item">
            <div className="item_title">
              PROJETOS RELEVANTES PARA O SEU COMÉRCIO
            </div>

            <p className="text">
              Criamos projetos personalizados para realçar o valor dos produtos
              aos seus clientes.
            </p>
          </div>

          <div className="item">
            <div className="item_title">ENTREGA E MONTAGEM</div>

            <p className="text">
              Realizamos a entrega e montagem do seu projeto em todo o
              território brasileiro, garantindo um serviço de excelência em cada
              etapa. Nossa prioridade é proporcionar uma qualidade impecável em
              todos os aspectos.
            </p>
          </div>

          <CTAWpp />
        </div>

        <figure className="right">
          <img
            src={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? about3Mobile
                : about3
            }
            alt="Exemplário de Loja"
          />
        </figure>
      </article>
    </section>
  );
};

export default About;
