import React from "react";
import "./styles.css";

import logo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <nav id="menu">
      <figure>
        <img src={logo} alt="Logo" />
      </figure>
    </nav>
  );
};

export default Menu;
